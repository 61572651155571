const paymentMethods = [
  {
    value: "pix",
    text: "Pix/Transf",
    color: "#829EE6",
  },
  {
    value: "paper",
    text: "Dinheiro",
    color: "#FFC165",
  },
  {
    value: "credit",
    text: "Crédito",
    color: "#8AE046",
  },
  {
    value: "debit",
    text: "Débito",
    color: "#F34871",
  },
];

export default paymentMethods;
